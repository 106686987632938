export default {

	resetSettingForDetailPage( state, data ){
		Object.assign(state, 
			{
	            setting: null,
	            diamond: null
	        }
        );
		
	},

	setSettingForDetailPage( state, data ){

		const activeMetal = data.metals.find( (cv) => cv.selected === 1 )

		if (activeMetal !== undefined && data.prong_tip !== undefined) {

			data.prong_tip = data.prong_tip.map( (cv) => {

	            cv.visibility = 0
	            cv.selected = 0 
	            // white is available in all 
	            if (cv.label == 'white') {
	                cv.visibility = 1 
	                
	            }
	            // toggle color specific
	            if (activeMetal.color === cv.label ) {

	                cv.visibility = 1 
	            }
	            return cv
	        })

			// const activeProng = 
		}

		state.setting = data
	},

	unsetSettingForDetailPage( state, data ){

		state.setting = null
	},

	setDiamondForDetailPage( state, data ){

		state.diamond = data
	},

	unsetDiamondForDetailPage( state ){
	
		state.diamond = null
	},


	changeSettingProngTipColorAttribute(state, data) {

        state.setting.prong_tip = state.setting.prong_tip.map( (cv) => {

            cv.visibility = 0
            cv.selected = 0 
            // white is available in all 
            if (cv.label == 'white') {
                cv.visibility = 1 
                
            }
            // toggle color specific
            if (data.option.color === cv.label ) {

                cv.visibility = 1 
            }
            return cv
        })

    },
	changeSettingAttribute( state, data ){
		// if (data.type === 'metal') {

			state.setting[data.type].map( (cv) =>{
				cv.selected = 0
				if (cv.label === data.option.label) {

					cv.selected = 1
				}
			})

		// }
	}
}