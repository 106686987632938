import { defineStore } from 'pinia'
import actions from './actions.js';
import mutations from './mutations.js';
import getters from './getters.js';


export const cyoStore = defineStore('cyov1', {
    state: () => {
        return {
            ring: {
                listfilters:{
                    setting:null,
                    diamond:null
                },
                diamond: null,
                setting: null,
                start_with: null,
                shape_images:[],
                form: {
                    shape: null,
                    size: null,
                    inscription:null,
                    prong_tip: null,
                    prong_type: null,
                    metal: null
                }
            },
            pendant: {
                listfilters:{
                    setting:null,
                    diamond:null
                },
                diamond: null,
                setting: null,
                start_with: null,
                shape_images:[],
                form: {
                    shape: null,
                    size: null,
                    chain_length: null,
                    metal: null
                }
            },
            earring: {
                listfilters:{
                    setting:null,
                    diamond:null
                },
                diamond: null,
                setting: null,
                start_with: null,
                shape_images:[],
                form: {
                    shape: null,
                    size: null,
                    chain_length: null,
                    metal: null
                }
            },
        }
    },
    getters: { ...getters},
    actions: {...actions, commitMutation: function( mutationToCall, data ) {

            // console.log('mutationToCall, data ', mutationToCall, data )
            try {
                mutations[mutationToCall]( this, data )
            } catch (error) {
                console.error( 'CYO MUTATION ERROR - ', this, mutationToCall, data, error )
                throw error
            }
        }
    },
    persist: true,
})
