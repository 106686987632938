
export default {
    resetCyoState( state, moduleSubType ){

        const copy = {
                diamond: null,
                setting: null,
                start_with: null,
                shape_images:[],
                listfilters:{
                    setting:null,
                    diamond:null
                },
                form: {
                    shape: null,
                    size: null,
                    metal: null
                }
            }

        if (moduleSubType === 'ring') {

            Object.assign(state[moduleSubType], { ...copy, form: { ...copy.form, inscription:null, prong_tip:null, prong_type:null } });
        }

        if (moduleSubType === 'earring') {

            Object.assign(state[moduleSubType], { ...copy });
        }

        if (moduleSubType === 'pendant') {

            Object.assign(state[moduleSubType], { ...copy });
        }
        
        
    },

    setListingPageUrl(state, {listingUrl, pageType,moduleSubType} ) {

        
        if ( state[moduleSubType] === undefined) {

            return
        }

        if (pageType === 'diamond') {

            state[moduleSubType].listfilters.diamond = listingUrl
        }
        if (pageType === 'setting') {
 
            state[moduleSubType].listfilters.setting = listingUrl
        }
    },

    setShape(state, data) {
        state[data.moduleSubType].form.shape = data.shape.value
    },

    setDiamond(state, data) {

        state[data.moduleSubType].diamond = {...data.item }

        if (!state[data.moduleSubType].start_with) {

            state[data.moduleSubType].start_with = 'diamond'
        }
    },

    changeSettingImagePath(state, data) {

        state[data.moduleSubType].setting.image = data.path
    },


    setSettingDetailItem(state, data) {

        // state.detail.setting = data
        
        // form:{
        //                 prong_type:this.selected.prong_type,
        //                 prong_tip:this.selected.prong_tip,
        //             },
        //             setting:{
        //                 name: this.item.product_name,
        //                 shapes:this.item.avail_shapes,
        //                 image:this.images.front_image,
        //                 all_images:this.item.all_images,
        //                 metal:this.selected.metal,
        //                 short_name:this.item.short_name
        //             }

        // state[data.moduleSubType].setting = {...data.item.setting }

        // state[data.moduleSubType].form.prong_type = data.item.form.prong_type
        // state[data.moduleSubType].form.prong_tip = data.item.form.prong_tip

        // if (!state[data.moduleSubType].start_with) {
        //     state[data.moduleSubType].start_with = 'setting'
        // }
    },

    setSetting(state, data) {

        state[data.moduleSubType].setting = data.state.setting
        // // state[data.moduleSubType].form    = { ...data.state.form, ...state.form}
        state[data.moduleSubType].form.prong_tip =  data.state.form.prong_tip
        state[data.moduleSubType].form.prong_type =  data.state.form.prong_type
        state[data.moduleSubType].form.shape =  data.state.form.shape

        
        if (!state[data.moduleSubType].start_with) {
            state[data.moduleSubType].start_with = 'setting'
        }

    },

    setSettingCustomAttributes(state, data) {

        state[data.moduleSubType].form = { ...state[data.moduleSubType].form, ...data }
    },


    unsetSetting(state, type) {
    
        if(state[type].start_with === 'setting' && state[type].diamond !== null){

            state[type].start_with = 'diamond'

        }
        if(state[type].start_with === 'diamond' && state[type].setting !== null){

            state[type].start_with = 'diamond'

        }else{
            state[type].start_with = null
        }
        
        state[type].setting = null
        state[type].form.prong_type = null
        state[type].form.prong_tip = null

        
    },
    
    unsetDiamond(state, type) {
        
        if(state[type].start_with === 'diamond' && state[type].setting !== null){
            
            state[type].start_with = 'setting' 

        }
        if(state[type].start_with === 'setting' && state[type].diamond !== null){
            
            state[type].start_with = 'setting' 

        }
        else{
            state[type].start_with = null
        }

        state[type].diamond = null

    },
}