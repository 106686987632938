import { defineStore } from 'pinia'
import actions from './actions.js';
import mutations from './mutations.js';
import getters from './getters.js';


export const cyodetailStore = defineStore('cyodetail', {
    state: () => {
        return {
            setting: null,
            diamond: null
        }
    },
    getters: { ...getters},
    actions: {...actions, commitMutation: function( mutationToCall, data ) {

        try {
            mutations[mutationToCall]( this, data )
        } catch (error) {
            console.error( 'CYO DETAIL MUTATION ERROR - ', mutationToCall, data, error )
            throw error
        }
    }},
    // persist: true,
})
