import { cyoStore }  from '../cyo/cyo';
export default {
    
    // getSettingMetals: (state) => (type) => {

    //     return state.setting.metals ? state.setting.metals : null
    // },
    getSetSettingAttributes: (state) => (type) => {
            
        const setting = state.getSettingDetail()
        const images = state.getSettingSliderImages(type, false)
        const shape_images = state.getSettingAllShapeImages(type, false)
        // 
        const metal = state.getSettingActiveVariation()
        const data = {
            name: setting.product_name,
            avail_shapes: setting.avail_shapes,
            image: images.front_image,
            short_name: setting.short_name,
            id: setting.id,

            shape_images: shape_images,
            metal: metal,
        }
        const prong_type = state.getSettingSelectedDropdownOption('prong_type')
        const prong_tip = state.getSettingSelectedDropdownOption('prong_tip')
        const shape = state.getSettingShape(type)


        const form = {
                        prong_type:state.getSettingSelectedDropdownOption('prong_type'),
                        prong_tip:state.getSettingSelectedDropdownOption('prong_tip'),
                        shape:shape
                    }

        return { setting :  data, form : form }
    },

    getSettingShape: (state) => (type) => {
        const cyo = cyoStore()
        let selectedShape = cyo.getShape(type)

        // // if shape is already selected in cyo process
        // if( selectedShape ){

        //     return selectedShape
        // }

        // if setting is absent and shape is true, shape is selected from setting list
        if (! state.setting) {

            return selectedShape
        }

        // is selected shape is not compatible with the setting then
        // we show first available shape of setting
        if (  state.setting && ! state.setting.avail_shapes.includes( selectedShape ) ) {

            selectedShape = state.setting.avail_shapes[0]
        }

        return selectedShape
    },

    getSettingDetail: (state) => (type) => {
        return state.setting
    },
    getSettingDropdowns: (state) => (type) => {
        
        if ( ! state.setting || ! state.setting[type] ) {
            return null
        }

        let activeMetals = state.setting[type]

        if (type === 'metals') {
            activeMetals = state.setting[type].filter( ( cv ) => cv.availability === 1 || cv.selected === 1) 

        }

        let active = null
        if(activeMetals){
            active = activeMetals.find( (cv) => cv.selected === 1 )
        }

        // if no option is selected then we make first option as selected
        if (! active && activeMetals ) {
            activeMetals[0].selected = 1
        } 
        
        return activeMetals
    },

    getSettingSelectedDropdownOption: (state) => (type) => {

        if (! state.setting[type]) {

            return null
        }
        // return null
        const option = state.setting[type].find( cv => cv.selected === 1 )
        return option ? option.value : null
    },
    
    getCyoSliderImages: (state) => (type, forPage = false) => {


        if (forPage === 'diamond') {
            
    
            const d = state.getDiamondDetail()
            if (!d) {

                return null
            }

            // to identify diamond pair
            if ( d.shape_one ) {

                return {
                    front_image : d.diamond_image_one, 
                    front_image_two : d.diamond_image_two, 

                    json_video : d.video_one, 
                    json_video_two : d.video_two, 
                    
                    // certificate_link : { src : d.certificate_link_one, shape: d.shape_one, lab: d.lab_one } , 
                    // certificate_link_two : { src : d.certificate_link_two, shape: d.shape_two, lab: d.lab_two } , 
                    
                    front_shape_measurment : { shape: d.shape_one, length : d.length_one , width : d.width_one }  ,
                    front_shape_measurment_two: { shape: d.shape_two, length : d.length_two , width : d.width_two } ,
                    
                    side_shape_measurment : { shape: d.shape_one, table : d.table_percent_one , depth : d.depth_percent_one, culet: d.culet_one, girdle:d.girdle_one },
                    
                    side_shape_measurment_two: { shape: d.shape_two, table : d.table_percent_two , depth : d.depth_percent_two, culet: d.culet_two, girdle:d.girdle_two }
                }

            }

            
            return {
                    front_image : d.diamond_image, 
                    json_video : d.video, 
                    // certificate_link : { src : d.certificate_link, shape: d.shape, lab: d.lab }, 
                    front_shape_measurment: { shape: d.shape, length : d.length , width : d.width } ,
                    side_shape_measurment: { shape: d.shape, table : d.table_percent , depth : d.depth_percent, culet: d.culet, girdle:d.girdle } 
                }

            // return { thumb_image : diamond.diamond_thumb_image, front_image : diamond.diamond_image, json_video : diamond.video }
        }


        let settingSliderImages = state.getSettingSliderImages( type, forPage )

        if(forPage === 'setting'){

            return settingSliderImages
        }
        
        const d = state.getDiamondDetail()

        if ( !settingSliderImages ) {

            return null
        }


        if (d && d.shape ) {

            const diamondImage = {
                    diamond_image : d.diamond_image, 
                    json_video : d.video, 
                    // certificate_link : { src : d.certificate_link, shape: d.shape, lab: d.lab }, 
                    // front_shape_measurment: { shape: d.shape, length : d.length , width : d.width } ,
                    // side_shape_measurment: { shape: d.shape, table : d.table_percent , depth : d.depth_percent, culet: d.culet, girdle:d.girdle } 
                }

            settingSliderImages = { ...settingSliderImages, ...diamondImage }
        }


        if (d && d.shape_one ) {

            const diamondImage = {
                    diamond_image : d.diamond_image_one, 
                    diamond_image_two : d.diamond_image_two, 

                    json_video : d.video_one, 
                    json_video_two : d.video_two, 
                    
                    // certificate_link : { src : d.certificate_link_one, shape: d.shape_one, lab: d.lab_one } , 
                    // certificate_link_two : { src : d.certificate_link_two, shape: d.shape_two, lab: d.lab_two } , 
                    
                    // front_shape_measurment : { shape: d.shape_one, length : d.length_one , width : d.width_one }  ,
                    // front_shape_measurment_two: { shape: d.shape_two, length : d.length_two , width : d.width_two } ,
                    
                    // side_shape_measurment : { shape: d.shape_one, table : d.table_percent_one , depth : d.depth_percent_one, culet: d.culet_one, girdle:d.girdle_one },
                    // side_shape_measurment_two: { shape: d.shape_two, table : d.table_percent_two , depth : d.depth_percent_two, culet: d.culet_two, girdle:d.girdle_two }
                }

            settingSliderImages = { ...settingSliderImages, ...diamondImage }  
        }

        if( settingSliderImages.model_image !== undefined ){

            const i = settingSliderImages['model_image']
            delete( settingSliderImages['model_image'] )
            settingSliderImages['model_image'] = i
        }

        if( settingSliderImages.side_image !== undefined ){
            
            const i = settingSliderImages['side_image']
            delete( settingSliderImages['side_image'] )
            settingSliderImages['side_image'] = i
        }

        if (settingSliderImages.side_left_image !== undefined) {

            const i = settingSliderImages['side_left_image']
            delete( settingSliderImages['side_left_image'] )
            settingSliderImages['side_left_image'] = i
        }

        return settingSliderImages

    },

    getSettingAllShapeImages: (state) => (type) => {

        if ( !state.setting ) {

            return null
        }
        let allimages = state.getSettingAllImages()
        if ( ! allimages ) {

            return null
        }

        return allimages.shapes
    },


	getSettingSliderImages: (state) => (type) => {

        if ( !state.setting ) {

            return null
        }
        let allimages = state.getSettingAllImages()
        if ( ! allimages ) {

            return null
        }

        // check if shape is set in state else set first shape from available setting available  shape
        // get state shape
        const cyo = cyoStore()
        let selectedShape = cyo.getShape(type) ? cyo.getShape(type) : 'round'
        // if shape is wrong then set first shape from setting
        if ( ! state.setting.avail_shapes.includes( selectedShape ) ) {

            selectedShape = state.setting.avail_shapes[0]
        }

        let val =  { front_image : allimages.shapes[ selectedShape ] , ...allimages.images } 


        for (const imageKey in val) {

            if (val[imageKey] === null || val[imageKey] === '' ) {

                delete( val[imageKey] )
            }
        }

        if(Object.keys(val).length === 0){

            return null
        }

        return val
    },

    getSettingAllImages: (state) => (type) => {

        const color = state.getSettingActiveColor()
        const allimages = state.setting.all_images.find( (cv) => cv.color === color ) 

        return allimages ? allimages : null
    },

    getSettingActiveColor: (state) => (type) => {

        const metal = state.getSettingActiveVariation()

        return metal ? metal.color : 'white'
    },

    getSettingActiveVariation: (state) => (type) => {

        if ( !state.setting ) {

            return null
        }

        const metal = state.setting.metals.find( cv => cv.selected === 1 )
        return metal
    },

    // -----------------------diamond-------------------------------------


    getDiamondSliderImages: (state) => (type) => {

        if (! state.diamond) {

            return null
        }


        const images = [state.diamond.diamond_image, state.diamond.video]
        return images
    },

    getDiamondDetail: (state) => (type) => {
        return state.diamond
    },

}