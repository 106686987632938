



const cyoDetailApiService =  ( vuexContext ) => ({

    async requestProductDetailApi( data, url ){

        try{
            
            const res = await vuexContext.$axios.$get( url );


            if (! res.data) {

                throw vuexContext.app.context.error(  { message :'missing data property in response cyoDetailApiService', status : 500}  )
            }

            if ( res.data.slug !== data.slug ) {

                const rname = vuexContext.$router.currentRoute.name

                const newPath = vuexContext.$router.resolve( {name: rname, params : { slug : res.data.slug }} )
                
                if (import.meta.client && newPath.href ) {
                    return vuexContext.$router.push( vuexContext.localePath( {name: rname, params : { slug : res.data.slug }} )  )
                }


                if ( import.meta.server && newPath.href ) {

                    return vuexContext.app.context.redirect( 302, vuexContext.localePath( newPath.href )  )


                }
                
            }

            return res.data

        }catch(error){

            if (error.response?.status === 404 || error.response?.status === 400) {


                // check if redirection exists
                try{
                    const queryParams = vuexContext.$router.currentRoute.query
                    
                    const data = await vuexContext.$axios.$get( process.env.apiPath+'redirect',  { params : { path :  vuexContext.$router.currentRoute.path } } )

                    if (data.data?.url.new_url) {

                        
                        let url = data.data?.url.new_url
                        if(queryParams){
                            const query = Object.entries(queryParams).map(([key,value]) => `${key}=${value}`).join('&');
                            url = `${data.data?.url.new_url}?${query}`
                        }

                        vuexContext.app.context.redirect( 302, vuexContext.localePath(url))
                    }

                }catch( e2 ){

                    console.log( e2, 'this is e2' )
                }




                throw vuexContext.app.context.error(  { message :'product not found cyoDetailApiService', status : 404}  )
            }

            throw error
        }

    },


    async requestDiamondForDetailPage( data ) {
        // console.log(data, 'datadatadatadata')
        const queryPath = data.moduleSubType === 'earring' ? '/diamond/loose-pair/detail/' : '/diamond/detail/'

        const config = useRuntimeConfig()
        // console.log('data', queryPath)

        const url = useCountryPath(queryPath + data.slug)

        return url
        return await this.requestProductDetailApi(data, url)

    },


    async setSettingForDetailPage( data ) {

        const config = useRuntimeConfig()

        // console.log('data', data)

        const url = useCountryPath('/config/setting/'+data.moduleSubType+'/detail/'+data.slug)
        return url
        return await this.requestProductDetailApi(data, url)
    },

});

export default cyoDetailApiService;