import cyoDetailApiService from '~/services/api/cyoDetailApiService';


export default {

    async setSettingForCompletePage(data){
        const setting = this.getSetting( data.moduleSubType )

        if (setting && setting.metal) {
            
            this.setSettingForDetailPage({ ...data, slug : setting.metal.slug })
        }
        
        return null
    },

    async setDiamondForCompletePage(data){

        const diamond = this.getDiamond( data.moduleSubType )

        if (diamond) {

            this.setDiamondForDetailPage({ ...data, slug : diamond.slug })
        }
        
        return null
    },


	async setSettingForDetailPage(data){
        
        const router = useRouter()
        const route = router.currentRoute.value

        try {

            const url = await cyoDetailApiService(this).setSettingForDetailPage(data)

            const res = await $fetch( url)

            this.commitMutation('setSettingForDetailPage', res.data)

            if( res.data?.slug && res.data?.slug !== route.params.slug ){

                const name = router.resolve( {name : route.name, params: {slug: res.data.slug} } )
                await navigateTo({ path: name.path, redirectCode: 301 })
                return
            }


        } catch (error) {
            
            if (error.response?.status === 404 || error.response?.status === 400) {

                const isDiamonddetail = route.name.includes( 'cyo-setting-detail' )

                if( isDiamonddetail  ){

                    const name = router.resolve( {name : route.name.replace('detail','list') } )
                    await navigateTo({ path: name.path, redirectCode: 301 })
                    return
                }
            }

            th
        }
    },

    async unsetSettingForDetailPage(){

        this.commitMutation('unsetSettingForDetailPage')
    },


    async changeSettingAttribute(data){

        if (data.type === 'metals' && data.moduleSubType === 'ring' ) {

            this.commitMutation( 'changeSettingProngTipColorAttribute', data )
        }

        this.commitMutation( 'changeSettingAttribute', data )
    },

    // ----------------------diamond------------------------------------

    async setDiamondForDetailPage(data){
     
        const router = useRouter()
        const route = router.currentRoute.value

        const url = await cyoDetailApiService(this).requestDiamondForDetailPage(data)

        try {
            
            const response  = await $fetch( url)
          
            this.commitMutation('setDiamondForDetailPage', response.data)

        } catch (error) {
            
            if (error.response?.status === 404 || error.response?.status === 400) {

                const isDiamonddetail = route.name.includes( 'cyo-diamond-detail' )

                if( isDiamonddetail  ){

                    const name = router.resolve( {name : route.name.replace('detail','list') } )
                    await navigateTo({ path: name.path, redirectCode: 301 })
                    return
                }
            }
        }

    },

    async unsetDiamondForDetailPage(){

        this.commit('unsetDiamondForDetailPage')
    },

}