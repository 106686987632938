import { cyodetailStore }  from '../cyodetail/cyodetail';
export default {
    getDiamond: (state) => (type) => {
        return state[type].diamond;
    },
    getSetting: (state) => (type) => {
        return state[type].setting;
    },
    getFormData: (state) => (type) => {
        return state[type].form;
    },
    getShape: (state) => (type) => {
        return state[type].form.shape
    },

    getListingUrl: (state) => (data) => {

        if( state[data.moduleSubType].listfilters !== undefined ){

            return state[data.moduleSubType].listfilters[ data.page ]
        }

        return null
    },

    getStartWith: (state) => (type) => {
        return state[type].start_with;
    },

    checkIfItemComplete: (state) => (type) => {
        return state[type].diamond && state[type].setting ? true : false
    },


    checkCompatibility: (state) => (data) => {
        const cyoDetail = cyodetailStore()
        if (import.meta.server) {
            
            return null
        }   

        if (data.page === 'setting') {
            
            const diamond = state.getDiamond( data.moduleSubType )

            // if diamond is not selected
            if(! diamond ){

                return true
            }

            const setting = cyoDetail.getSettingDetail( data.moduleSubType )

            if( data.moduleSubType === 'earring' ){

                return setting.avail_shapes.includes( diamond.shape_one ) ? true : false
            }

            return setting.avail_shapes.includes( diamond.shape ) ? true : false
        }


        if (data.page === 'diamond') {

            const setting = state.getSetting( data.moduleSubType )
            

            if (!setting) {
                
                return true
            }

            const diamond = cyoDetail.getDiamondDetail( )

            // for earring
            if ( data.moduleSubType === 'earring' ) {

                return setting.avail_shapes.includes( diamond.shape_one ) ? true : false
            }

            // for rinng and pendant
            return setting.avail_shapes.includes( diamond.shape ) ? true : false
        }

        if (data.page === 'complete') {

            const setting = state.getSetting( data.moduleSubType )
            const diamond = state.getDiamond( data.moduleSubType )
            
            if (!setting || !diamond ) {
                
                return true
            }

            if (data.moduleSubType === 'earring') {

                return setting.avail_shapes.includes( diamond.shape_one ) ? true : false
            }


            return setting.avail_shapes.includes( diamond.shape ) ? true : false
        }

        
        return null
    },

}